import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import getCities from "../util/db.js";
import milan_logo from "../milan_logo.png";
import Select from "@material-ui/core/Select";
import {
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  FormControl,
  FormHelperText,
  Button,
  InputAdornment,
  CircularProgress
} from "@material-ui/core";
import DateTimePicker from "react-datetime-picker";
import axios from "axios";

function EventRequest() {
  const [password, setPassword] = useState("");
  const [location, setLocation] = useState("Omaha West");
  const [locations, setLocations] = useState([]);
  const [eventData, setEventData] = useState({
    name: "",
    email: "",
    radioDM: "Yes",
    noOfTeam: "",
    radioAvailability: "Yes",
    radioTypeOfEvent: "Super Referrer",
    radioEventTimeline: "Yes",
    costOfEvent: "",
    budget: "",
    eventLocation: "",
    eventLocationWebsite: "",
    radioOutdoors: "Yes",
    eventSpace: "",
    hostName: "",
    howManyGuests: "",
    radioCanopy: "Yes",
    radioChairs: "Yes",
    radioEmailSent: "Yes",
    radioEmailReferrer: "Yes",
    radioDrawing: "Yes",
    radioSpecial: "50%",
    additional_notes: ""
  });
  const [subitError, setSubmitError] = useState(false);
  const [eventStartDate, onStartChange] = useState(new Date());
  const [eventEndDate, onEndChange] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    getCities.then(res => {
      setLocations(res);
    });
  }, []);

  const handleSelectChange = e => {
    setLocation(e.target.value);
  };

  const handleChange = e => {
    setEventData({ ...eventData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (
      eventData.name === "" ||
      eventData.email === "" ||
      eventData.noOfTeam === "" ||
      eventData.costOfEvent === "" ||
      eventData.budget === "" ||
      eventData.eventLocation === "" ||
      eventData.eventSpace === "" ||
      eventData.howManyGuests === "" ||
      eventData.radioDM === "No"
    ) {
      setSubmitError(true);
    } else {
      setLoading(true);
      console.log(eventData);
      let form_data = {
        name: eventData.name,
        date_now: new Date(),
        email: eventData.email,
        location: location,
        dm_approval: eventData.radioDM,
        team_agreed: eventData.noOfTeam,
        follow_up: eventData.radioAvailability,
        event_type: eventData.radioTypeOfEvent,
        event_timeline: eventData.radioEventTimeline,
        start_time: eventStartDate,
        end_time: eventEndDate,
        cost: eventData.costOfEvent,
        budget: eventData.budget,
        event_location: eventData.eventLocation,
        website: eventData.eventLocationWebsite,
        event_special: eventData.radioSpecial,
        outdoors: eventData.radioOutdoors,
        space: eventData.eventSpace,
        guests: eventData.guests,
        event_email: eventData.radioEmailSent,
        event_referrer: eventData.radioEmailReferrer,
        event_chairs: eventData.radioChairs,
        event_canopy: eventData.radioCanopy,
        drawing: eventData.radioDrawing,
        host_name: eventData.hostName
      };
      axios
        .post(
          "https://request.milanlaser.com/events.php",
          { form_data },
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Cache-Control": "no-cache"
            }
          }
        )
        .then(res => {
          console.log(res);
          setSuccess(true);
        });
    }
  };

  return (
    <Grid container style={{ alignItems: "center", flexDirection: "column" }}>
      <img alt="logo" src={milan_logo} width={200} />
      <Typography variant="h4">Event Request Form</Typography>
      {password === "7157" ? (
        loading ? (
          success ? (
            <>
              <Typography style={{ marginTop: "5%" }} variant="h6">
                Thank You! Your request has been submitted.
              </Typography>
              <Typography style={{ marginTop: "1%" }} variant="h6">
                {" "}
                The marketing Team will reach out to let you know of the status
                within 48 hours.
              </Typography>
            </>
          ) : (
            <div style={{ marginTop: "5%" }}>
              <CircularProgress />
            </div>
          )
        ) : (
          <Grid
            container
            style={{
              margin: "2%",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <TextField
              label="Your Name"
              name="name"
              value={eventData.name}
              onChange={handleChange}
              variant="outlined"
              style={{ margin: 15, width: "35%" }}
            ></TextField>
            <TextField
              label="Your Email"
              name="email"
              value={eventData.email}
              onChange={handleChange}
              variant="outlined"
              style={{ margin: 15, width: "35%" }}
            ></TextField>

            <FormControl style={{ width: "35%", margin: 15 }}>
              <FormHelperText>Your Location</FormHelperText>
              <Select
                value={location}
                onChange={handleSelectChange}
                variant="outlined"
              >
                {locations.map(loc => {
                  return (
                    <MenuItem key={loc.value} value={loc.value}>
                      {loc.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div style={{ width: "35%", margin: 15, textAlign: "left" }}>
              <FormControl component="fieldset" style={{ marginLeft: 15 }}>
                <FormLabel component="legend">
                  Has this event been approved by your RSM?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radioDM"
                  value={eventData.radioDM}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
                {eventData.radioDM === "No" && (
                  <FormHelperText error>
                    You need RSM's approval to host an event.
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <div style={{ width: "35%", margin: 15 }}>
              <FormHelperText className="datePickerLabel">
                How many members of your team have agreed to work this event?
              </FormHelperText>
              <TextField
                variant="outlined"
                name="noOfTeam"
                value={eventData.noOfTeam}
                onChange={handleChange}
                style={{ width: "100%" }}
              ></TextField>
            </div>
            <div style={{ width: "35%", margin: 15, textAlign: "left" }}>
              <FormControl component="fieldset" style={{ marginLeft: 15 }}>
                <FormLabel component="legend">
                  Will you be available after the event to complete follow-up
                  tasks?
                </FormLabel>
                <FormHelperText>
                  (Must be available the week after the event to
                  participate/host an event.)
                </FormHelperText>
                <RadioGroup
                  aria-label="gender"
                  name="radioAvailability"
                  value={eventData.radioAvailability}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ width: "35%", margin: 15, textAlign: "left" }}>
              <FormControl component="fieldset" style={{ marginLeft: 15 }}>
                <FormLabel component="label">Type of Event </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radioTypeOfEvent"
                  value={eventData.radioTypeOfEvent}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Super Referrer"
                    control={<Radio color="primary" />}
                    label="Super Referrer (Current Client Hosting Event at Milan Clinic)"
                  />
                  <FormControlLabel
                    value="Tent Event"
                    control={<Radio color="primary" />}
                    label="Tent Event"
                  />
                </RadioGroup>
                <FormHelperText>
                  Milan Parties are organized and communicated by Marketing. If
                  you have any questions, please email{" "}
                  <a href="mailto:events@milanlaser.com">
                    events@milanlaser.com
                  </a>
                </FormHelperText>
              </FormControl>
            </div>
            <div style={{ width: "35%", margin: 15, textAlign: "left" }}>
              <FormControl component="fieldset" style={{ marginLeft: 15 }}>
                <FormLabel component="label">
                  Does this event fall within the event timeline? (4-6 Weeks){" "}
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radioEventTimeline"
                  value={eventData.radioEventTimeline}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <TextField
              label="Cost of Event"
              variant="outlined"
              style={{ margin: 15, width: "35%" }}
              onChange={handleChange}
              value={eventData.costOfEvent}
              name="costOfEvent"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            ></TextField>
            <TextField
              label="What is the budget for this event? (includes food, drinks, chairs, etc.)"
              onChange={handleChange}
              value={eventData.budget}
              name="budget"
              variant="outlined"
              style={{ margin: 15, width: "35%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            ></TextField>
            <div style={{ width: "35%", margin: 15 }}>
              <FormHelperText className="datePickerLabel">
                Event Start Date &amp; Time
              </FormHelperText>
              <DateTimePicker onChange={onStartChange} value={eventStartDate} />
            </div>
            <div style={{ width: "35%", margin: 15 }}>
              <FormHelperText className="datePickerLabel">
                Event End Date &amp; Time
              </FormHelperText>
              <DateTimePicker onChange={onEndChange} value={eventEndDate} />
            </div>
            <TextField
              label="Event Location"
              name="eventLocation"
              value={eventData.eventLocation}
              onChange={handleChange}
              variant="outlined"
              style={{ margin: 15, width: "35%" }}
            ></TextField>
            <TextField
              label="Event Location Website (Optional)"
              value={eventData.eventLocationWebsite}
              name="eventLocationWebsite"
              onChange={handleChange}
              variant="outlined"
              style={{ margin: 15, width: "35%" }}
            ></TextField>
            <div style={{ width: "35%", margin: 15, textAlign: "left" }}>
              <FormControl component="fieldset" style={{ marginLeft: 15 }}>
                <FormLabel component="legend">
                  Is this event outdoors?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radioOutdoors"
                  value={eventData.radioOutdoors}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <TextField
              label="Event Space Capacity"
              name="eventSpace"
              value={eventData.eventSpace}
              onChange={handleChange}
              variant="outlined"
              style={{ margin: 15, width: "35%" }}
            ></TextField>

            <TextField
              label="Host Name (Optional)"
              name="hostName"
              value={eventData.hostName}
              onChange={handleChange}
              variant="outlined"
              style={{ margin: 15, width: "35%" }}
            ></TextField>
            <TextField
              label="How many guests are invited?"
              value={eventData.howManyGuests}
              name="howManyGuests"
              onChange={handleChange}
              variant="outlined"
              style={{ margin: 15, width: "35%" }}
            ></TextField>
            <div style={{ width: "35%", margin: 15, textAlign: "left" }}>
              <FormControl component="fieldset" style={{ marginLeft: 15 }}>
                <FormLabel component="legend">
                  Does this event require a canopy?{" "}
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radioCanopy"
                  value={eventData.radioCanopy}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ width: "35%", margin: 15, textAlign: "left" }}>
              <FormControl component="fieldset" style={{ marginLeft: 15 }}>
                <FormLabel component="legend">
                  Does your event provide chairs?{" "}
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radioChairs"
                  value={eventData.radioChairs}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ width: "35%", margin: 15, textAlign: "left" }}>
              <FormControl component="fieldset" style={{ marginLeft: 15 }}>
                <FormLabel component="legend">
                  Will your event need an email sent?{" "}
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radioEmailSent"
                  value={eventData.radioEmailSent}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ width: "35%", margin: 15, textAlign: "left" }}>
              <FormControl component="fieldset" style={{ marginLeft: 15 }}>
                <FormLabel component="legend">
                  Will your event need an invite for the referrer?{" "}
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radioEmailReferrer"
                  value={eventData.radioEmailReferrer}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ width: "35%", margin: 15, textAlign: "left" }}>
              <FormControl component="fieldset" style={{ marginLeft: 15 }}>
                <FormLabel component="legend">
                  Is there a drawing for this event/party? (Must be approved by
                  RSM)
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radioDrawing"
                  value={eventData.radioDrawing}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ width: "35%", margin: 15, textAlign: "left" }}>
              <FormControl component="fieldset" style={{ marginLeft: 15 }}>
                <FormLabel component="legend">
                  What is the Special offered at this event/party?{" "}
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radioSpecial"
                  value={eventData.radioSpecial}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="50%"
                    control={<Radio color="primary" />}
                    label="50%"
                  />
                  <FormControlLabel
                    value="60%"
                    control={<Radio color="primary" />}
                    label="60%"
                  />
                  <FormControlLabel
                    value="Peel &amp; Reveal (30%, 40% &amp; 50% Off)"
                    control={<Radio color="primary" />}
                    label="Peel &amp; Reveal (30%, 40% &amp; 50% Off)
"
                  />
                  <FormControlLabel
                    value="Scratch &amp; Match (30%, 40% &amp; 50% Off)"
                    control={<Radio color="primary" />}
                    label="Scratch &amp; Match (30%, 40% &amp; 50% Off)"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ width: "100%" }}>
              <TextField
                label="Additional notes for Marketing/DMs"
                value={eventData.additional_notes}
                name="additional_notes"
                onChange={handleChange}
                multiline
                variant="outlined"
                style={{ alignSelf: "center", margin: 15, width: "80%" }}
              ></TextField>
            </div>
            {subitError && (
              <div style={{ width: "100%", textAlign: "center", margin: 15 }}>
                <Typography style={{ color: "red" }}>
                  Please fill all the required fields
                </Typography>
              </div>
            )}
            <Button
              variant="contained"
              onClick={handleSubmit}
              className="submitButton"
              color="primary"
            >
              Submit Your Request
            </Button>
          </Grid>
        )
      ) : (
        <TextField
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          variant="outlined"
          style={{ marginTop: 25 }}
          label="Enter the Password"
          error={password.length > 3 && password !== "7157"}
          helperText={
            password.length > 3 && password !== "7157" && "Incorrect Password"
          }
        />
      )}
    </Grid>
  );
}

export default EventRequest;
