//import * as firebase from "firebase/app"// Required for side-effects
import { initializeApp } from 'firebase/app';
import {getFirestore, collection, getDocs, query, orderBy} from 'firebase/firestore/lite';
//require("firebase/firestore");
/*firebase.default.initializeApp({
    apiKey: "AIzaSyAneBh2iwpeHoAJ4_cGoEbqK8z4h2TMssI",
    authDomain: "milanlaser-fcb24.firebaseapp.com",
    databaseURL: "https://milanlaser-fcb24.firebaseio.com",
    projectId: "milanlaser-fcb24",
    storageBucket: "milanlaser-fcb24.appspot.com",
    messagingSenderId: "652981090258",
    appId: "1:652981090258:web:692ed02b9e7490c694ba0b",
    measurementId: "G-8YMSQLT4NH"
  });*/

  const firebaseConfig = {
    apiKey: "AIzaSyAneBh2iwpeHoAJ4_cGoEbqK8z4h2TMssI",
    authDomain: "milanlaser-fcb24.firebaseapp.com",
    databaseURL: "https://milanlaser-fcb24.firebaseio.com",
    projectId: "milanlaser-fcb24",
    storageBucket: "milanlaser-fcb24.appspot.com",
    messagingSenderId: "652981090258",
    appId: "1:652981090258:web:692ed02b9e7490c694ba0b",
    measurementId: "G-8YMSQLT4NH"
  }
//firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
export var db = getFirestore(app);
async function getCities(db) {
    var citiesCol = collection(db, 'locations');
    const q = query(citiesCol, orderBy("label"));
    const citySnapshot = await getDocs(q);
    const cityList = citySnapshot.docs.map(doc => doc.data());
    
    return cityList;

  }
 



export default getCities(db);

