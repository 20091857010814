import './App.css';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import EventRequest from './components/EventRequest';
import EventRequestFollowup from './components/EventRequestFollowup';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <EventRequest />
          </Route>
          <Route exact path="/followup">
            <EventRequestFollowup />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
